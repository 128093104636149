import styled from 'styled-components';

export const TitleFormSpan = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* letter-spacing: 1px; */
    /* text-transform: uppercase; */
`;
