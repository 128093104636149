export enum Roles {
    notAuthenticated = -1,
    administrator = 0,
    OrganizationAdministrator = 1,
    OrganizationMember = 2,
    CampaignAdministrator = 3
}

export const accessRoles: Record<Roles, string> = {
    [Roles.notAuthenticated]: 'notAuthenticated',
    [Roles.administrator]: 'administrator',
    [Roles.OrganizationAdministrator]: 'OrganizationAdministrator',
    [Roles.OrganizationMember]: 'OrganizationMember',
    [Roles.CampaignAdministrator]: 'CampaignAdministrator'
};
