import { flexCenter } from 'constants/styles';
import styled from 'styled-components';

export const Plugin = styled.div`
    width: 245px;
    height: 530px;
`;

export const PluginWrapper = styled.div`
    ${flexCenter}
`;

export const ProductViewerWrapper = styled.div`
    height: 720px;
    border-radius: 8px;
    margin-right: 20px;
    background: #fff;
    padding: 20px;
    min-width: 325px;
    overflow-x: hidden;
`;
