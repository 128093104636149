import { Roles } from 'constants/roles';
import { routes } from 'constants/routes';
import { useStore } from 'effector-react';
import React from 'react';
import { RouteProps } from 'react-router';
import { AccessRoute } from 'routes/AccessRoute';
import { themeStores } from 'stores/theme';

export const PublicRoute = (props: RouteProps) => {
    const globalPrefixUrl = useStore(themeStores.globalPrefixUrl);

    return (
        <AccessRoute
            proxy={[Roles.notAuthenticated]}
            {...props}
            redirectTo={globalPrefixUrl + routes.userAdmin.index}
        />
    );
};
