import { Roles } from 'constants/roles';
import { parsePublicUrl, signInIndexTemplate } from 'constants/routes';
import { useStore } from 'effector-react';
import React from 'react';
import { RouteProps } from 'react-router';
import { AccessRoute } from 'routes/AccessRoute';
import { themeStores } from 'stores/theme';

export const CampaignManagerRoute = (props: RouteProps) => {
    const { prefix } = useStore(themeStores.globalPrefix);

    return (
        <AccessRoute
            proxy={[Roles.administrator, Roles.OrganizationAdministrator, Roles.OrganizationMember]}
            {...props}
            redirectTo={parsePublicUrl(prefix || '', signInIndexTemplate)}
        />
    );
};
