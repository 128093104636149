import { defaultLimit, defaultPage } from 'constants/defaults';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { API } from 'services';

const defaultValues = {
    returnQueryCount: true,
    pageIndex: defaultPage,
    limit: defaultLimit
};

const updateValues = createEvent<Partial<WOM.QueryProductViewerContentRequest>>();
const setItems = createEvent<WOM.QueryProductViewerContentResponse>();
const setDefaultValues = createEvent();
const updateLoading = createEvent();
const setLoading = createEvent<boolean>();

const getItems = createEffect({
    handler: async (values: WOM.QueryProductViewerContentRequest) => {
        try {
            updateLoading();
            const data = await API.productViewerContent.getItems(values);

            return data || {};
        } catch {
            return {};
        } finally {
            updateLoading();
        }
    }
});

const blockVideo = createEffect({
    handler: async (values: WOM.BlockProductViewerContentRequest) => {
        try {
            updateLoading();
            const data = await API.productViewerContent.blockVideo(values);

            return data || {};
        } catch {
            return {};
        } finally {
            updateLoading();
        }
    }
});

const items = createStore<WOM.QueryProductViewerContentResponse>({})
    .on(getItems.doneData, (_, newState) => newState)
    .on(setItems, (_, newState) => newState);

interface Response {
    params: WOM.BlockProductViewerContentRequest;
    result: WOM.MessageResponseBase;
}

// if query for blocking video is successful then we update isBlocked state for video item
sample({
    clock: blockVideo.done,
    source: items,
    fn: (prevItems: WOM.QueryProductViewerContentResponse, response: Response) => {
        if (response.result.isSuccess && prevItems.items) {
            const newItems = prevItems.items.map(item => {
                if (item.id === response.params.contentId) {
                    return { ...item, isBlocked: !item.isBlocked };
                }
                return item;
            });
            return { ...prevItems, items: newItems };
        }
        return prevItems;
    },
    target: setItems
});

const loading = createStore<boolean>(false)
    .on(updateLoading, state => !state)
    .on(setLoading, (_, newState) => newState);

const values = createStore<WOM.QueryProductViewerContentRequest>(defaultValues)
    .on(updateValues, (state, values: Partial<WOM.QueryProductViewerContentRequest>) => ({
        ...state,
        ...values
    }))
    .on(setDefaultValues, () => defaultValues);
values.watch(updateValues, state => getItems(state));

const productsViewerContentEvents = {
    updateValues
};
const productsViewerContentEffects = {
    getItems,
    blockVideo
};
const productsViewerContentStores = { items, loading, values };

export { productsViewerContentEvents, productsViewerContentEffects, productsViewerContentStores };
