import styled from 'styled-components';
import { ImgButton } from 'components/common/buttons/ImgButton';
import { black } from 'constants/styles';

export const HoveredPlayButton = styled(ImgButton)`
    background-color: transparent;

    &:hover svg circle {
        opacity: 1;
        transition: opacity 0.3s;
    }
`;

export const HoveredAddButton = styled(ImgButton)`
    background-color: transparent;
    margin-left: 34px;
    svg {
        width: 100%;
        height: 100%;
    }

    &:hover svg circle {
        fill: ${black};
        transition: fill 0.3s;
    }
`;

export const BackDrop = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.2);
`;
