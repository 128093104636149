import { Row } from 'components/grid/wrappers/FlexWrapper';
import styled from 'styled-components';

export const ActionsWrapper = styled(Row)`
    align-items: center;
    justify-content: flex-end;
    gap: 27px;
    margin-top: 30px;
    margin-bottom: 16px;
`;
