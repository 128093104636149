import { grey9 } from 'constants/styles';
import styled from 'styled-components';

export const Wrapper = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const BlockedSpan = styled.span`
    font-size: 12px;
    line-height: 12px;
    color: ${grey9};
`;
