import { Loader } from 'components/dynamic/Loader';
import { Section } from 'components/grid/wrappers/FlexWrapper';
import { CampaignManagerLayout } from 'components/Layouts/CampaignManagerLayout';
import { defaultPage } from 'constants/defaults';
import { useStore } from 'effector-react';
import { PlaylistTab } from 'pages/CampaignManager/Products/Product/PlaylistTab';
import { LoaderWrapper, StyledContentWrapper } from 'pages/CampaignManager/Products/Product/styles';
import React, { useEffect, useState } from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { campaignContentEvents } from 'stores/campaignContent';
import { organizationsStores } from 'stores/organizations';
import { productsStores } from 'stores/products';
import { productsViewerContentEffects } from 'stores/productsViewerContent';
import { Actions } from './Actions';
import { productContentMarginBottom } from './constants';
import { ProductViewer } from './ProductViewer';

export enum Tabs {
    Documents,
    Playlist
}

export const Product = () => {
    const [activeTab, setActiveTab] = useState<number>(Tabs.Playlist);
    const [isPlaylistEditing, setIsPlaylistEditing] = useState<boolean>(false);
    const { brand, name, publicId } = useStore(productsStores.item);
    const { publicId: organizationPublicId } = useStore(organizationsStores.item);
    const organizationPublicIdString = typeof organizationPublicId === 'string' ? organizationPublicId : '';
    const docs = [{ uri: require('assets/pdf/WOM_Product_Viewer.pdf') }];
    const isBlockVideoLoading = useStore(productsViewerContentEffects.blockVideo.pending);

    useEffect(
        () => {
            name &&
                brand &&
                campaignContentEvents.updateValues({
                    tagsAny: [name, brand, `${name} ${brand}`],
                    tagsAll: undefined,
                    pageIndex: defaultPage
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [name, brand]
    );

    return (
        <CampaignManagerLayout>
            <Actions
                isPlaylistEditing={isPlaylistEditing}
                setActiveTab={setActiveTab}
                setIsPlaylistEditing={setIsPlaylistEditing}
            />
            <Section noWrap marginBottom={productContentMarginBottom}>
                <ProductViewer />
                <StyledContentWrapper borderRadius="8px" padding="16px 20px">
                    {activeTab === Tabs.Documents && organizationPublicIdString && publicId && (
                        <DocViewer
                            config={{
                                header: {
                                    disableHeader: true,
                                    disableFileName: true
                                }
                            }}
                            documents={docs}
                            pluginRenderers={DocViewerRenderers}
                        />
                    )}
                    {activeTab === Tabs.Playlist && <PlaylistTab isPlaylistEditing={isPlaylistEditing} />}
                    {isBlockVideoLoading && (
                        <LoaderWrapper>
                            <Loader />
                        </LoaderWrapper>
                    )}
                </StyledContentWrapper>
            </Section>
        </CampaignManagerLayout>
    );
};
