import noVideosProductViewer from 'assets/img/noVideosProductViewer.png';
import WOMLogo from 'assets/img/sample_logo.png';
import { CopyableField } from 'components/common/features/CopyableField';
import { CustomImg } from 'components/common/imageComponents/CustomImg';
import { Span } from 'components/common/typography/Span';
import { Row, Section } from 'components/grid/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { isDev } from 'constants/global';
import { useStore } from 'effector-react';
import { NoVideosContainer, NoVideosText, ProductThumbnail } from 'pages/CampaignManager/Products/styles';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { campaignContentEvents } from 'stores/campaignContent';
import { organizationsStores } from 'stores/organizations';
import { productsEffects, productsEvents, productsStores } from 'stores/products';
import { getFullScriptStringProductViewer } from 'utils/usefulFunctions';
import { Plugin, PluginWrapper, ProductViewerWrapper } from './styles';

interface ParamsProps {
    productId: string;
}

export const ProductViewer = () => {
    const { productId } = useParams<ParamsProps>();
    const { imageUrl, name, publicId } = useStore(productsStores.item);
    const { publicId: organizationPublicId } = useStore(organizationsStores.item);
    const organizationPublicIdString = typeof organizationPublicId === 'string' ? organizationPublicId : '';

    useEffect(
        () => {
            productsEffects.getItemById(productId).then(({ publicId }) => {
                if (organizationPublicIdString !== '') {
                    const initData = {
                        organizationPublicId: organizationPublicIdString,
                        selector: '#wom-viewer-plugin',
                        remoteProductId: publicId,
                        color: '#3333FF',
                        textColor: 'white',
                        isDev
                    };
                    // @ts-ignore
                    window.wom.check(initData).then(result => {
                        if (result[0].isSuccess) {
                            // @ts-ignore
                            window.wom.init(initData);
                        } else {
                            console.log('no videos');
                        }
                    });
                }
            });
            return () => {
                campaignContentEvents.setDefaultValues();
                productsEvents.resetItem();
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [organizationPublicIdString]
    );

    return (
        <ProductViewerWrapper>
            <Section alignCenter noWrap marginBottom="20px" marginTop="10px">
                <MarginWrapper marginRight="17px">
                    <ProductThumbnail>
                        <CustomImg src={imageUrl || WOMLogo} />
                    </ProductThumbnail>
                </MarginWrapper>
                <Row marginBottom="10px" width="200px">
                    <Span noWrap fontWeight="400" lineHeight="17px">
                        {name}
                    </Span>
                </Row>
            </Section>
            <MarginWrapper marginBottom="20px">
                <Row marginBottom="20px">
                    <CopyableField
                        data={getFullScriptStringProductViewer(organizationPublicIdString, publicId || '')}
                        maxWidth="300px"
                        subject={`publicId=${publicId}`}
                    />
                </Row>
                <PluginWrapper>
                    <Plugin id="wom-viewer-plugin">
                        <NoVideosContainer>
                            <CustomImg src={noVideosProductViewer} />
                            <NoVideosText>
                                <Span alignCenter color="#fff" fontWeight="400" lineHeight="17px">
                                    There are no videos for this product recorded.
                                </Span>
                            </NoVideosText>
                        </NoVideosContainer>
                    </Plugin>
                </PluginWrapper>
            </MarginWrapper>
        </ProductViewerWrapper>
    );
};
