import { Loader } from 'components/dynamic/Loader';
import { Column, Section } from 'components/grid/wrappers/FlexWrapper';
import { EmptySearchResult } from 'components/Layouts/EmptySearchResult';
import { Pagination } from 'components/Layouts/Pagination';
import { useStore } from 'effector-react';
import { CardGrid } from 'pages/CampaignManager/Products/Product/styles';
import React, { FC, useEffect } from 'react';
import { organizationsStores } from 'stores/organizations';
import { productsStores } from 'stores/products';
import { productsViewerContentEvents, productsViewerContentStores } from 'stores/productsViewerContent';
import { getTotalItems } from 'utils/usefulFunctions';
import { BlockWrapperVideoCard } from '../BlockWrapperVideoCard';

const { updateValues } = productsViewerContentEvents;

interface Props {
    isPlaylistEditing: boolean;
}

export const PlaylistTab: FC<Props> = ({ isPlaylistEditing }) => {
    const { publicId } = useStore(productsStores.item);
    const { items, totalRecords } = useStore(productsViewerContentStores.items);
    const loading = useStore(productsViewerContentStores.loading);
    const { pageIndex, limit } = useStore(productsViewerContentStores.values);
    const { publicId: organizationPublicId } = useStore(organizationsStores.item);

    useEffect(() => {
        updateValues({ organizationPublicId, remoteProductId: publicId });
    }, [organizationPublicId, publicId]);

    const onPaginationChange = (current: number) => {
        updateValues({ pageIndex: current });
    };

    const onSizeChange = (current: number, size: number) => {
        updateValues({ pageIndex: current, limit: size });
    };

    if (loading && !items?.length) {
        return (
            <Section>
                <Loader />
            </Section>
        );
    }

    if (!items?.length) {
        return (
            <Section justifyCenter>
                <EmptySearchResult title="Sorry! No result found" />
            </Section>
        );
    }

    return (
        <Column alignCenter justifyBetween height="100%">
            <CardGrid>
                {items.map(item => (
                    <BlockWrapperVideoCard
                        key={item.id}
                        {...item}
                        isPlaylistEditing={isPlaylistEditing}
                        organizationPublicId={organizationPublicId || ''}
                        remoteProductId={publicId || ''}
                    />
                ))}
            </CardGrid>
            <Section justifyCenter marginBottom="35px" marginTop="45px">
                <Pagination
                    currentIndex={pageIndex + 1}
                    customSizes={['10', '20', '50']}
                    defaultSize={limit}
                    totalItems={getTotalItems(totalRecords)}
                    onChange={onPaginationChange}
                    onSizeChange={onSizeChange}
                />
            </Section>
        </Column>
    );
};
