import {
    buttonBorderRadius,
    buttonBorderWidth,
    buttonHeight,
    buttonWidth,
    hoverButtonColor
} from 'components/common/buttons/SimpleButton/constants';
import { ButtonProps } from 'components/common/buttons/SimpleButton/types';
import {
    black,
    buttonActiveMixin,
    buttonDisabledMixin,
    disableDefaultButtonStyleMixin,
    transitionTime,
    white
} from 'constants/styles';
import styled from 'styled-components';

export const Button = styled.button<ButtonProps>`
    ${disableDefaultButtonStyleMixin};
    min-width: ${({ minWidth }) => minWidth ?? buttonWidth};
    height: ${({ height }) => height ?? buttonHeight};
    background-color: ${({ backgroundColor }) => backgroundColor ?? black};
    border: ${buttonBorderWidth} solid ${({ backgroundColor }) => backgroundColor ?? 'none'};
    border-radius: ${buttonBorderRadius};
    color: ${({ color }) => color ?? white};
    margin-bottom: ${({ marginBottom }) => marginBottom ?? '0'};
    padding-right: ${({ paddingLeft }) => paddingLeft ?? '5px'};
    padding-left: ${({ paddingLeft }) => paddingLeft ?? '5px'};
    transition: ${transitionTime};
    z-index: 2;

    :hover {
        background-color: ${hoverButtonColor};
    }

    :disabled {
        ${buttonDisabledMixin};
    }

    :active {
        ${buttonActiveMixin};
    }
`;
