import { Roles } from 'constants/roles';
import { routes } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { RouteProps } from 'react-router';
import { AccessRoute } from 'routes/AccessRoute';
import { themeStores } from 'stores/theme';

export const UserAdminRoute: FC<RouteProps> = props => {
    const globalPrefixUrl = useStore(themeStores.globalPrefixUrl);

    return (
        <AccessRoute
            proxy={[Roles.administrator, Roles.OrganizationAdministrator]}
            {...props}
            redirectTo={globalPrefixUrl + routes.campaignManager.index}
        />
    );
};
