import { SimpleButton } from 'components/common/buttons/SimpleButton';
import { MarginWrapper } from 'components/grid/wrappers/MarginWrapper';
import { VideoCard } from 'components/Layouts/Cards/VideoCard';
import { lightPink, red } from 'constants/styles';
import React, { FC } from 'react';
import { modalEvents } from 'stores/modal';
import { productsViewerContentEffects } from 'stores/productsViewerContent';
import { BlockedSpan, Wrapper } from './styles';

interface Props extends WOM.ContentResponse {
    organizationPublicId: string;
    remoteProductId: string;
    isPlaylistEditing: boolean;
}

export const BlockWrapperVideoCard: FC<Props> = ({
    organizationPublicId,
    remoteProductId,
    isPlaylistEditing,
    isBlocked,
    id,
    ...props
}) => {
    const toggleBlock = () => {
        modalEvents.openAsyncModal({
            title: 'Are you sure you want to edit the playlist?',
            closeText: 'No',
            okText: 'Yes',
            onOk: () => {
                productsViewerContentEffects.blockVideo({
                    organizationPublicId,
                    remoteProductId,
                    contentId: id,
                    isBlocked: !isBlocked
                });
                modalEvents.closeAsyncModal();
            }
        });
    };

    return (
        <VideoCard
            hasBackDrop
            {...props}
            additionalContent={
                <>
                    <Wrapper>
                        {isBlocked && <BlockedSpan>This video is blocked</BlockedSpan>}
                        {isPlaylistEditing && (
                            <MarginWrapper marginLeft="12px">
                                <SimpleButton
                                    backgroundColor={lightPink}
                                    color={red}
                                    fontSize="12px"
                                    paddingLeft="16px"
                                    paddingRight="16px"
                                    onClick={toggleBlock}
                                >
                                    {isBlocked ? 'UnBlock' : 'Block'}
                                </SimpleButton>
                            </MarginWrapper>
                        )}
                    </Wrapper>
                </>
            }
            showStatistic={false}
            streamDetails={props.streaming}
        />
    );
};
