import womChannelViewerImage from 'assets/img/womChannelViewerPreview.png';
import { SimpleButton } from 'components/common/buttons/SimpleButton';
import { CustomImg } from 'components/common/imageComponents/CustomImg';
import { InfoPopover } from 'components/modals/InfoPopover';
import { blue, red } from 'constants/styles';
import { Tabs } from 'pages/CampaignManager/Products/Product';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { ActionsWrapper } from './styles';

interface Props {
    isPlaylistEditing: boolean;
    setActiveTab: Dispatch<SetStateAction<number>>;
    setIsPlaylistEditing: Dispatch<SetStateAction<boolean>>;
}

export const Actions: FC<Props> = ({ setActiveTab, isPlaylistEditing, setIsPlaylistEditing }) => {
    const onEditTabClick = () => {
        setActiveTab(Tabs.Playlist);
        setIsPlaylistEditing(prev => (prev ? false : true));
    };

    const onDocTabClick = () => {
        setActiveTab(Tabs.Documents);
        setIsPlaylistEditing(false);
    };

    return (
        <ActionsWrapper>
            <InfoPopover
                content={
                    <CustomImg alt="WOM Channel Viewer" height="190px" src={womChannelViewerImage} width="135px" />
                }
                height="auto"
                padding="30px 34px"
                width="203px"
            >
                <SimpleButton
                    backgroundColor="transparent"
                    color={blue}
                    fontSize="12px"
                    minWidth="auto"
                    onClick={onDocTabClick}
                >
                    WOM Product Viewer Usage Documentation
                </SimpleButton>
            </InfoPopover>
            <SimpleButton
                backgroundColor="transparent"
                color={isPlaylistEditing ? red : blue}
                fontSize="12px"
                minWidth="auto"
                onClick={onEditTabClick}
            >
                {isPlaylistEditing ? 'Cancel' : 'Edit Playlist'}
            </SimpleButton>
        </ActionsWrapper>
    );
};
