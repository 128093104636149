import { ContentWrapper } from 'components/grid/wrappers/NewDesign/ContentWrapper/styles';
import { flexCenter } from 'constants/styles';
import styled from 'styled-components';

export const CardGrid = styled.div`
    display: grid;
    gap: 16px;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    justify-items: center;
    width: 100%;
`;

export const LoaderWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    ${flexCenter};
    top: 0;
    left: 0;
    border-radius: 8px;
`;

export const StyledContentWrapper = styled(ContentWrapper)`
    position: relative;
`;
