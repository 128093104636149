export const formWidth = '700px';

export const formBorderRadius = '20px';

export const formVerticalPadding = '75px';
export const formHorizontalPadding = '162px';
export const formPaddingBottom = '62px';

export const logoDiameter = '49px';

export const mainTitle = '';
